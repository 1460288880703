import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosInstance } from "../../constants/axiosInstance";


const Earnings = () => {
  const [earnings, setEarnings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEarnings = async () => {
    try {
      const earningsResponse = await axiosInstance.get('/api/v1/earnings', {
        headers: {
          'Cache-Control': 'no-cache'
        }
      });

      console.log('Full API response:', earningsResponse.data);

      if (earningsResponse.data.success && earningsResponse.data.data) {
        setEarnings(earningsResponse.data.data);
      } else {
        setEarnings(null);
        setError('Unexpected data format received from server');
      }
    } catch (err) {
      console.error('Error fetching earnings:', err.response || err);
      setError(err.response?.data?.message || 'An error occurred while fetching earnings');
      setEarnings(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEarnings();

    // Set up polling every 30 seconds
    const pollInterval = setInterval(fetchEarnings, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(pollInterval);
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );

  if (error) return (
    <div className="max-w-2xl mx-auto my-8 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
      <p className="font-bold">Error</p>
      <p>{error}</p>
    </div>
  );

  if (!earnings) return (
    <div className="flex justify-center items-center h-full">
      <div className="text-center">
        <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No earnings</h3>
        <p className="mt-1 text-sm text-gray-500">No earnings data available at the moment.</p>
      </div>
    </div>
  );

  // Check if the updatedAt value is valid
  const updatedAt = earnings.updatedAt ? new Date(earnings.updatedAt) : null;
  const formattedDate = updatedAt && !isNaN(updatedAt.getTime()) 
    ? updatedAt.toLocaleString() 
    : "Date not available";

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6 text-center">Earnings Dashboard</h2>
        
        <div className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg shadow-lg p-6 mb-8 max-w-md mx-auto">
          <h3 className="text-xl font-semibold mb-2 text-center">Total Earnings</h3>
          <p className="text-3xl font-bold text-center">${earnings.totalEarnings.toFixed(2)}</p>
        </div>

        <div className="bg-white rounded-lg shadow-md overflow-hidden w-full max-w-sm mx-auto">
          <div className="bg-gradient-to-r from-blue-500 to-teal-400 px-4 py-2">
            <h3 className="text-lg font-semibold text-white truncate">All-time Earnings</h3>
          </div>
          <div className="p-4">
            <p className="text-2xl font-bold text-gray-800 mb-2 text-center">
              ${earnings.totalEarnings.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
