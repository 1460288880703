import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import { useAlert } from 'react-alert';
import "@reach/combobox/styles.css";
import { axiosInstance } from "../../constants/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { selectProgressBarState } from "../../redux/Actions/ProgressBarActions";
import { Loader } from "./Loader";

// Map constants
const containerStyle = {
  width: "90%",
  height: '80vh'
};
const center = { lat: 40.7810694898019, lng: -102.88417905250878 };

export default function Places() {
  const [selected, setSelected] = useState(null);
  const [radius, setRadius] = useState('');
  const [formattedAddress, setFormattedAddress] = useState('');
  const [shopName, setShopName] = useState('');
  const [delivery, setDelivery] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [placesArr, setPlacesArr] = useState([]);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(false);
  const [editId, setEditId] = useState('');
  const alert = useAlert();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ProgressBarReducer);
  const token = useSelector((state) => state.ProfileReducer);

  useEffect(() => {
    if (token) {
      getRadius();
    }
  }, [render, token]);

  const getRadius = async () => {
    dispatch(selectProgressBarState(true));
    try {
      const res = await axiosInstance.get('/api/v1/admin/getradius', {
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      });
      if (res.data.success) {
        dispatch(selectProgressBarState(false));
        console.log(res.data.data, " :radius data");
        setPlacesArr(res.data.data);
        setCount(res.data.data.length);
      } else {
        dispatch(selectProgressBarState(false));
        alert.show('No Radius Found');
      }
    } catch (error) {
      dispatch(selectProgressBarState(false));
      alert.show('Error fetching radius');
    }
  };

  const setRadiusApi = async () => {
    if (placesArr.length === 0) {
      alert.show('No new places to save');
      return;
    }
  
    dispatch(selectProgressBarState(true));
    try {
      const res = await axiosInstance.post('/api/v1/admin/setradius', placesArr, {
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      });
      if (res.data.success) {
        dispatch(selectProgressBarState(false));
        alert.show('Radius added successfully', {
          onClose: () => {
            getRadius();
            setCount(0);
            setRender(!render);
          }
        });
  
        // Clear the placesArr after saving
        setPlacesArr([]);
      } else {
        dispatch(selectProgressBarState(false));
        alert.show(`Error: ${res.data.message || 'Could not save radius'}`);
      }
    } catch (error) {
      dispatch(selectProgressBarState(false));
      console.error('Error details:', error); // Log error details
      alert.show(`Error saving radius: ${error.response?.data?.message || error.message}`);
    }
  };
  
  const updateBackendAfterDelete = async () => {
    dispatch(selectProgressBarState(true));
    try {
      const res = await axiosInstance.post('/api/v1/admin/setradius', placesArr, {
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      });
      if (res.data.success) {
        dispatch(selectProgressBarState(false));
        alert.show('Backend updated successfully', {
          onClose: () => {
            getRadius();
            setRender(!render);
          }
        });
      } else {
        dispatch(selectProgressBarState(false));
        alert.show(`Error: ${res.data.message || 'Could not update backend'}`);
      }
    } catch (error) {
      dispatch(selectProgressBarState(false));
      console.error('Error details:', error); // Log error details
      alert.show(`Error updating backend: ${error.response?.data?.message || error.message}`);
    }
  };

  const addRadius = () => {
    if (selected && radius && shopName && delivery && phoneNumber) {
      // Check if the place already exists in the placesArr
      const existingPlace = placesArr.find(
        (place) =>
          place.formattedAddress === formattedAddress &&
          place.shopName === shopName &&
          place.delivery === delivery &&
          place.phoneNumber === phoneNumber &&
          place.radius === radius &&
          place.geometry.coordinates[0] === selected.lng &&
          place.geometry.coordinates[1] === selected.lat
      );
  
      if (!existingPlace) {
        setPlacesArr([
          ...placesArr,
          {
            geometry: { coordinates: [selected.lng, selected.lat] },
            radius: radius,
            formattedAddress: formattedAddress,
            shopName: shopName,
            delivery: delivery,
            phoneNumber: phoneNumber,
          },
        ]);
        setRadius('');
        setSelected(null);
        setFormattedAddress('');
        setShopName('');
        setDelivery('');
        setPhoneNumber('');
        setCount(count + 1);
      } else {
        alert.show('This place already exists in the list.');
      }
    } else {
      alert.show('Please fill all fields');
    }
  };
  
  const editRadius = async (editId) => {
    const geometry = { coordinates: [selected.lng, selected.lat] };
    const body = {
      radius: radius,
      formattedAddress: formattedAddress,
      delivery: delivery,
      phoneNumber: phoneNumber,
      shopName: shopName,
      geometry: geometry,
    };
    try {
      const res = await axiosInstance.patch('/api/v1/admin/updateradius', body, {
        params: { id: editId }
      });
      if (res.data.success) {
        dispatch(selectProgressBarState(false));
        alert.show('Radius updated successfully');
        setTimeout(() => {
          window.location = '/add-shop';
        }, 2000);
      } else {
        dispatch(selectProgressBarState(false));
        alert.show('Could not save radius');
      }
    } catch (error) {
      dispatch(selectProgressBarState(false));
      alert.show('Error updating radius');
    }
  };

  return (
    <>
      {!loading ? (
        <div className='divide-y py-8 divide-gray-100 bg-white rounded-lg shadow-lg'>
          <div className="flex flex-col items-center justify-between gap-4">
            <PlacesAutocomplete
              setSelected={setSelected}
              selected={selected}
              radius={radius}
              setRadius={setRadius}
              setFormattedAddress={setFormattedAddress}
              formattedAddress={formattedAddress}
              setShopName={setShopName}
              shopName={shopName}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              setDelivery={setDelivery}
              delivery={delivery}
              placesArr={placesArr}
              editId={editId}
            />
            <Map
              selected={selected}
              radius={radius}
              placesArr={placesArr}
              editId={editId}
            />
            {editId.length < 1 && selected && radius && shopName && delivery && phoneNumber &&
              <button onClick={addRadius} className='py-2 px-4 bg-myBg text-xs rounded-lg hover:bg-[#efca37]'>
                Add Place
              </button>
            }
            {editId && radius && shopName && delivery && phoneNumber &&
              <button onClick={() => editRadius(editId)} className='py-2 px-4 bg-myBg text-xs rounded-lg hover:bg-[#efca37]'>
                Edit Place
              </button>
            }
          </div>
          {placesArr.length > 0 &&
            <div className="mx-8">
              <Areas
                placesArr={placesArr}
                setPlacesArr={setPlacesArr}
                setRadiusApi={setRadiusApi}
                count={count}
                setCount={setCount}
                setEditId={setEditId}
                setSelected={setSelected}
                setFormattedAddress={setFormattedAddress}
                setShopName={setShopName}
                setDelivery={setDelivery}
                setPhoneNumber={setPhoneNumber}
                setRadius={setRadius}
                updateBackendAfterDelete={updateBackendAfterDelete}
              />
            </div>
          }
          {placesArr.length === 0 && count === 0 && (
            <div className="flex justify-center mt-4">
              <button onClick={updateBackendAfterDelete} className='py-2 px-4 bg-red-500 text-xs text-white rounded-lg'>
                Update Backend
              </button>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

function Map({ selected, radius, placesArr }) {
  return (
    <GoogleMap mapContainerStyle={containerStyle} center={selected || center} zoom={selected ? 15 : 4}>
      {selected && (
        <>
          <Marker position={selected} />
          <Circle center={selected} radius={parseFloat(radius) * 1000} options={{ fillColor: 'red', fillOpacity: 0.2, strokeWeight: 1 }} />
        </>
      )}
      {placesArr.map((place, index) => (
        <Circle
          key={index}
          center={{ lat: place.geometry.coordinates[1], lng: place.geometry.coordinates[0] }}
          radius={place.radius * 1000}
          options={{ fillColor: 'blue', fillOpacity: 0.2, strokeWeight: 1 }}
        />
      ))}
    </GoogleMap>
  );
}

function PlacesAutocomplete({ setSelected, radius, setRadius, setFormattedAddress, formattedAddress, setShopName, shopName, setPhoneNumber, phoneNumber, setDelivery, delivery }) {
  const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete({
    requestOptions: { location: { lat: () => 40.7810694898019, lng: () => -102.88417905250878 }, radius: 100 * 1000 },
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    setFormattedAddress(address);
  };

  return (
    <>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput value={value} onChange={(e) => setValue(e.target.value)} disabled={!ready} placeholder="Enter address" className="py-2 px-4 border rounded-lg w-64" />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" && data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      <div className="flex flex-col mt-2">
        <input type="text" value={radius} onChange={(e) => setRadius(e.target.value)} placeholder="Radius in Km" className="py-2 px-4 border rounded-lg mt-2" />
        <input type="text" value={shopName} onChange={(e) => setShopName(e.target.value)} placeholder="Shop Name" className="py-2 px-4 border rounded-lg mt-2" />
        <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" className="py-2 px-4 border rounded-lg mt-2" />
        <input type="text" value={delivery} onChange={(e) => setDelivery(e.target.value)} placeholder="Delivery fee" className="py-2 px-4 border rounded-lg mt-2" />
      </div>
    </>
  );
}

function Areas({ placesArr, setPlacesArr, setRadiusApi, count, setCount, setEditId, setSelected, setFormattedAddress, setShopName, setDelivery, setPhoneNumber, setRadius, updateBackendAfterDelete }) {
  const deleteRadius = (index) => {
    const updatedPlaces = [...placesArr];
    updatedPlaces.splice(index, 1);
    setPlacesArr(updatedPlaces);
    setCount(count - 1);
    if (updatedPlaces.length === 0) {
      updateBackendAfterDelete();
    }
  };

  const editRadius = (index) => {
    const place = placesArr[index];
    setSelected({ lat: place.geometry.coordinates[1], lng: place.geometry.coordinates[0] });
    setFormattedAddress(place.formattedAddress);
    setShopName(place.shopName);
    setDelivery(place.delivery);
    setPhoneNumber(place.phoneNumber);
    setRadius(place.radius);
    setEditId(place._id);
  };

  return (
    <>
      {placesArr.map((place, index) => (
        <div key={index} className="flex justify-between items-center mt-4 py-2 px-4 border rounded-lg">
          <div className="flex flex-col">
            <span className="font-semibold">{place.shopName}</span>
            <span>{place.formattedAddress}</span>
            <span>Radius: {place.radius} Km</span>
          </div>
          <div className="flex gap-2">
            <button onClick={() => editRadius(index)} className="py-1 px-3 bg-yellow-400 text-xs rounded-lg">Edit</button>
            <button onClick={() => deleteRadius(index)} className="py-1 px-3 bg-red-400 text-xs rounded-lg">Delete</button>
          </div>
        </div>
      ))}
      {placesArr.length > 0 && (
        <div className="flex justify-center mt-4">
          <button onClick={setRadiusApi} className="py-2 px-4 bg-myBg text-xs rounded-lg hover:bg-[#efca37]">Save All</button>
        </div>
      )}
    </>
  );
}
