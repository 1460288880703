import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../constants/axiosInstance';
import { useAlert } from 'react-alert';

const DiscountManagement = () => {
  const [discounts, setDiscounts] = useState([]);
  const [newDiscount, setNewDiscount] = useState({
    code: '',
    type: 'percentage',
    value: 0,
    minOrderValue: 0,
    maxDiscount: null,
    expiryDate: '',
    isActive: true
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const alert = useAlert();

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const fetchDiscounts = async () => {
    try {
      const response = await axiosInstance.get('/api/v1/discount');
      setDiscounts(response.data.data);
    } catch (error) {
      alert.error('Failed to fetch discounts');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDiscount({ ...newDiscount, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEditing) {
        // Update existing discount
        await axiosInstance.put(`/api/v1/discount/${editId}`, newDiscount);
        alert.success('Discount updated successfully');
      } else {
        // Create new discount
        await axiosInstance.post('/api/v1/discount/create', newDiscount);
        alert.success('Discount created successfully');
      }
      
      fetchDiscounts();
      setNewDiscount({
        code: '',
        type: 'percentage',
        value: 0,
        minOrderValue: 0,
        maxDiscount: null,
        expiryDate: '',
        isActive: true
      });
      setIsEditing(false);
      setEditId(null);
    } catch (error) {
      alert.error('Failed to save discount');
    }
  };

  const handleEdit = (discount) => {
    setNewDiscount(discount);
    setIsEditing(true);
    setEditId(discount._id);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/v1/discount/${id}`);
      alert.success('Discount deleted successfully');
      fetchDiscounts();
    } catch (error) {
      alert.error('Failed to delete discount');
    }
  };

  return (
    <div className="flex">
      <div className="w-64 bg-gray-800 text-white h-screen p-4">
        <h2 className="text-xl font-bold">Sidebar</h2>
        <ul>
          <li className="my-2">Dashboard</li>
          <li className="my-2">Discounts</li>
        </ul>
      </div>

      <div className="flex-1 p-6 bg-gray-100 min-h-screen">
        <div className="container mx-auto">
          <h1 className="text-2xl font-bold mb-4">Discount Management</h1>
          
          <form onSubmit={handleSubmit} className="mb-8 p-6 bg-white shadow-md rounded-md">
            <h2 className="text-lg font-semibold mb-4">
              {isEditing ? 'Edit Discount' : 'Create New Discount'}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <input
                type="text"
                name="code"
                value={newDiscount.code}
                onChange={handleInputChange}
                placeholder="Discount Code"
                className="border p-3 rounded-md shadow-sm"
                required
              />
              <select
                name="type"
                value={newDiscount.type}
                onChange={handleInputChange}
                className="border p-3 rounded-md shadow-sm"
                required
              >
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed Amount</option>
              </select>
              <input
                type="number"
                name="value"
                value={newDiscount.value || ''}
                onChange={handleInputChange}
                placeholder="Discount Value"
                className="border p-3 rounded-md shadow-sm"
                required
              />
              <input
                type="number"
                name="minOrderValue"
                value={newDiscount.minOrderValue || ''}
                onChange={handleInputChange}
                placeholder="Minimum Order Value"
                className="border p-3 rounded-md shadow-sm"
              />
              <input
                type="number"
                name="maxDiscount"
                value={newDiscount.maxDiscount || ''}
                onChange={handleInputChange}
                placeholder="Max Discount Amount"
                className="border p-3 rounded-md shadow-sm"
              />
              <input
                type="date"
                name="expiryDate"
                value={newDiscount.expiryDate}
                onChange={handleInputChange}
                className="border p-3 rounded-md shadow-sm"
                required
              />
            </div>
            <button type="submit" className="mt-6 bg-blue-500 text-white p-3 rounded-md shadow hover:bg-blue-600">
              {isEditing ? 'Update Discount' : 'Create Discount'}
            </button>
          </form>

          <div className="bg-white shadow-md rounded-md p-6 overflow-x-auto">
            <h2 className="text-lg font-semibold mb-4">Existing Discounts</h2>
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border p-3 text-left">Code</th>
                  <th className="border p-3 text-left">Type</th>
                  <th className="border p-3 text-left">Value</th>
                  <th className="border p-3 text-left">Min Order</th>
                  <th className="border p-3 text-left">Max Discount</th>
                  <th className="border p-3 text-left">Expiry Date</th>
                  <th className="border p-3 text-left">Status</th>
                  <th className="border p-3 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {discounts.map((discount) => (
                  <tr key={discount._id} className="hover:bg-gray-100">
                    <td className="border p-3">{discount.code}</td>
                    <td className="border p-3">{discount.type}</td>
                    <td className="border p-3">{discount.value}</td>
                    <td className="border p-3">{discount.minOrderValue}</td>
                    <td className="border p-3">{discount.maxDiscount || 'N/A'}</td>
                    <td className="border p-3">{new Date(discount.expiryDate).toLocaleDateString()}</td>
                    <td className="border p-3">{discount.isActive ? 'Active' : 'Inactive'}</td>
                    <td className="border p-3">
                      <button
                        onClick={() => handleEdit(discount)}
                        className="text-blue-500 mr-3"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(discount._id)}
                        className="text-red-500"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountManagement;
