import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "../../constants/axiosInstance";
import { selectProgressBarState } from '../../redux/Actions/ProgressBarActions';
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { SlickSliderForHeaderImages } from "../minor-components/slider/SlickSlider";
import { baseURL } from "../../constants/baseURL";
import { Loader } from "../minor-components/Loader";

export const AddHeaderImage = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.ProgressBarReducer);

    const [moreExtras, setMoreExtras] = useState(1);
    const [editHeaderPreview, setEditHeaderFilePreview] = useState([]);
    const [editSliderPreview, setEditSliderFilePreview] = useState('');
    const [selectedImages, setSelectedImages] = useState(["", "", ""]);
    const [headerImages, setHeaderImages] = useState([]);
    const [sliderImage, setSliderImage] = useState(null);
    const [sliderImagePreview, setSliderImagePreview] = useState(null);

    useEffect(() => {
        getHeaderImages();
    }, []);

    const getHeaderImages = async () => {
        try {
            const res = await axiosInstance.get('/api/v1/headerimages/getHeaderImages');
            const images = res.data.data;
            if (images.length > 0) {
                setEditHeaderFilePreview(images[0].headerPhotos || []);
                setMoreExtras(images[0].headerPhotos?.length || 1);
                setEditSliderFilePreview(images[0].sliderPhoto || '');
                setHeaderImages(images[0].headerPhotos || []);
            }
        } catch (err) {
            console.error("Error fetching header images:", err);
            alert.error('Failed to fetch header images');
        }
    };

    const handleImageChange = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const newSelectedImages = [...selectedImages];
            const newHeaderImages = [...headerImages];

            // Update preview URL for the selected image
            newSelectedImages[index] = URL.createObjectURL(file);
            
            // Add the new image file to the headerImages array
            if (newHeaderImages[index]) {
                newHeaderImages[index] = file;  // Replace if already exists
            } else {
                newHeaderImages.push(file); // Append if it does not exist
            }

            setSelectedImages(newSelectedImages);
            setHeaderImages(newHeaderImages);
        }
    };

    const handleImageSubmit = async (e) => {
        e.preventDefault();
        dispatch(selectProgressBarState(true));
    
        const formData = new FormData();
        headerImages.forEach((image, index) => {
            if (image) {
                formData.append(`headerimage${index + 1}`, image);
            }
        });
    
        try {
            const response = await axiosInstance.post("/api/v1/headerimages/addheaderImage", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            dispatch(selectProgressBarState(false));
            alert.success('Successfully updated Header Image');
            getHeaderImages(); // Refresh the images
        } catch (error) {
            console.error("Error uploading header image:", error);
            dispatch(selectProgressBarState(false));
            alert.error('Failed to update Header Image');
        }
    };

    const handleSliderImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSliderImage(file);
            setSliderImagePreview(URL.createObjectURL(file));
        }
    };

    const submitBottomImage = async (e) => {
        e.preventDefault();
        dispatch(selectProgressBarState(true));

        if (!sliderImage) {
            alert.error('Please select an image');
            dispatch(selectProgressBarState(false));
            return;
        }

        const formData = new FormData();
        formData.append('sliderPhoto', sliderImage);

        try {
            await axiosInstance.post("/api/v1/headerimages/addbottomImage", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            dispatch(selectProgressBarState(false));
            alert.success('Successfully updated Bottom Image');
            getHeaderImages(); // Refresh the images
        } catch (error) {
            dispatch(selectProgressBarState(false));
            alert.error('Failed to update Bottom Image');
            console.error("Error uploading bottom image:", error);
        }
    };

    const handleCount = () => {
        setMoreExtras(prev => {
            const newMoreExtras = prev + 1;
            setSelectedImages([...selectedImages, ""]);
            setHeaderImages([...headerImages, null]); // Adding a new slot for the new image
            return newMoreExtras;
        });
    };

    const decreaseCross = (index) => {
        setMoreExtras(prev => Math.max(1, prev - 1));
        setSelectedImages(selectedImages.filter((_, i) => i !== index));
        setHeaderImages(headerImages.filter((_, i) => i !== index));
    };

    return (
        <div className="bg-gray-50 z-0">
            {!loading ? (
                <div className="mt-24 bg-gray-50 ml-[20%] w-[78%]">
                    <div className='py-2 bg-gray-50'>
                        <div className='bg-white rounded-lg shadow-lg'>
                            <div className='px-5 pt-4 mb-4 flex justify-between'>
                                <h2 className='font-semibold text-gray-800 text-lg'>Add Header Slider</h2>
                                <Link to='/pages'>
                                    <button className='bg-myBg text-gray-600 px-4 py-2 rounded-full cursor-pointer hover:bg-[#E9D95D]'>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className="pr-2" />
                                        Back
                                    </button>
                                </Link>
                            </div>
                            <label className="block mb-2 text-sm font-bold text-gray-700 md:mt-2 text-center">
                                Header Slider
                            </label>
                            <SlickSliderForHeaderImages>
                                {Array.from({ length: moreExtras }).map((_, index) => (
                                    <div key={index} className="w-[100%] h-96 rounded-lg relative bg-cover bg-fixed flex flex-col justify-center items-center">
                                        {index !== 0 && (
                                            <span className='cursor-pointer absolute right-0 m-3 px-1 py-1 bg-white rounded-full' onClick={() => decreaseCross(index)}>
                                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                            </span>
                                        )}
                                        <label className="w-[100%] h-96 cursor-pointer border-2 rounded-lg flex justify-center">
                                            {!selectedImages[index] && !editHeaderPreview[index] ? (
                                                <h3 className="self-center">Click to Upload Header Slider Image {index + 1}</h3>
                                            ) : (
                                                <img 
                                                    src={editHeaderPreview[index] ? `${baseURL}${editHeaderPreview[index]}` : selectedImages[index]} 
                                                    className="w-[100%] h-full object-cover" 
                                                    alt={`Header ${index + 1}`}
                                                />
                                            )}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className='hidden'
                                                onChange={(event) => handleImageChange(event, index)}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </SlickSliderForHeaderImages>
                            <label className="block mb-2 mt-2 text-right text-sm font-bold text-myBg cursor-pointer md:mt-2" onClick={handleCount}>
                                + Add More
                            </label>
                            <div className="flex items-center justify-center pb-6 mt-16 px-4">
                                <button onClick={handleImageSubmit} type='button' className='bg-myBg text-gray-600 px-4 py-2 rounded-full cursor-pointer hover:bg-[#E9D95D]'>
                                    {editHeaderPreview.length ? 'Update Header Image' : 'Add Header Image'}
                                </button>
                            </div>
                            <label className="block mb-2 text-sm font-bold text-gray-700 md:mt-2 text-center">
                                Bottom Image
                            </label>
                            <div className="w-[100%] h-96 mb-3 rounded-lg bg-cover bg-fixed flex flex-col justify-center items-center">
                                <label className="w-[100%] h-96 cursor-pointer border-2 rounded-lg flex justify-center">
                                    {!sliderImagePreview && !editSliderPreview ? (
                                        <h3 className="self-center">Click to Upload Bottom Image</h3>
                                    ) : (
                                        <img 
                                            src={sliderImagePreview || `${baseURL}${editSliderPreview}`} 
                                            className="w-[100%] h-full object-cover" 
                                            alt="Bottom Image"
                                        />
                                    )}
                                    <input
                                        className='hidden'
                                        id="upload"
                                        name="headerimage"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleSliderImageChange}
                                    />
                                </label>
                            </div>
                            <div className="flex items-center justify-center py-6 px-4">
                                <button onClick={submitBottomImage} className='bg-myBg text-gray-600 py-2 px-4 rounded-full cursor-pointer hover:bg-[#E9D95D]'>
                                    {editSliderPreview ? 'Update Bottom Image' : 'Add Bottom Image'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};
