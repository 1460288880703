export const ACTION_TYPES = {
    //products
    SET_PRODUCT: "SET_PRODUCT",
    GET_PRODUCTS: "GET_PRODUCTS",
    DELETE_PRODUCTS: "DELETE_PRODUCTS",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",

    //Categories
    SET_CATEGORY: "SET_CATEGORY",
    GET_CATEGORIES: "GET_CATEGORIES",
    DELETE_CATEGORIES: "DELETE_CATEGORIES",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",

    
    //Categories-Banner
    SET_BANNER_CATEGORY: "SET_BANNER_CATEGORY",
    GET_BANNER_CATEGORIES: "GET_BANNER_CATEGORIES",
    DELETE_BANNER_CATEGORIES: "DELETE_BANNER_CATEGORIES",
    UPDATE_BANNER_CATEGORY: "UPDATE_BANNER_CATEGORY",

    
    //drivers
    GET_UNAPPROVED_DRIVERS: "GET_UNAPPROVED_DRIVERS",
    UPDATE_DRIVER_STATUS: "UPDATE_DRIVER_STATUS",
    
    //Orders
    GET_ORDER_BY_ID: "GET_ORDER_BY_ID",

    //Users
    GET_UNAPPROVED_USERS: "GET_UNAPPROVED_USERS",
    GET_DEACTIVATE_USERS: "GET_DEACTIVATE_USERS",
    UPDATE_USER_STATUS: "UPDATE_USER_STATUS",

    
    //Blogs
    SET_BLOG: "SET_BLOG",
    GET_BLOGS: "GET_BLOGS",
    GET_SINGLE_BLOG: "GET_SINGLE_BLOG",
    DELETE_BLOG: "DELETE_BLOG",
    UPDATE_BLOG: "UPDATE_BLOG",

    //Social Links
    SET_SOCIAL_LINK: "SET_SOCIAL_LINK",
    GET_SOCIAL_LINKS: "GET_SOCIAL_LINKS",
    GET_SINGLE_SOCIAL_LINK: "GET_SINGLE_SOCIAL_LINK",   

    //progress
    PROGRESS_BAR_STATE: "PROGRESS_BAR_STATE",

    //login
    LOGIN: "LOGIN"
}